:root {
  --white: #eee;
  --text: #0f0f0f;
  --divider: #999;

  --header: #f3f3f3;
  --header-background: #0766ad;

  --footer: #f3f3f3;
  --footer-background: #0766ad;

  --sidebar-bg: #0766ad;
  --sidebar-c: #eee;
  --sidebar-active: #5c85cb;
  --sidebar-hover: #104d95;

  --primary: #0766ad;
  --secondary: #bdcee1;
  --success: #09b081;
  --danger: #cf4240;
  --warning: #f7d002;
  // --info: #a1cbc9;
}

$primary: #0766ad;
// $secondary: #bdcee1;
$success: #09b081;
$danger: #cf4240;
$warning: #f7d002;
// $info: #a1cbc9;

$input-border-color: #0766ad;

