.page-container {
  width: 100vw;
  max-width: 100vw;
  min-height: 100%;
  height: 100%;
  padding: 2em 0;

  // @include responsive(sm) {
  //   padding: 1em 0;
  // }
}

// Bootstrpa settings
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
}

.w-fit {
  width: fit-content;
}
// END Bootstrpa settings

.crud-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.crud-content {
  flex: 1;
}

.table-crud-header,
.table-crud-header tr,
.table-crud-header th {
  background-color: var(--primary) !important;
  border-color: #111 !important;
}

.table-crud-header th:not(:last-child) {
  border-right-color: #fff !important;
}

.crud-table {
  th {
    padding: 1em 0.5em !important;
    vertical-align: middle !important;
  }

  .td-small {
    text-align: end;
  }
}

.crud-footer {
  padding-bottom: 1em;
}
