@import "assets/css/mixins";
@import "assets/css/fonts";
@import "assets/css/global";
@import "assets/css/variables";
@import "assets/css/animation";
@import "assets/css/material-theme";
@import "assets/css/containers";

html,
body {
  margin: 0;
  padding: 0;
  // font-family: "AcuminProMedium", sans-serif !important;
  color: #000;
  line-height: normal !important;
}

body {
  height: 100%;
  width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

:host {
  min-height: 100%;
  width: 100%;
}

// SCROLL BAR
::-webkit-scrollbar-thumb {
  background: #c2c2c2;
  border: 0 #fff;
  border-radius: 15px;
}
::-webkit-scrollbar {
  width: 5px !important;
}
::-webkit-scrollbar-corner {
  background: transparent !important;
}
::-webkit-scrollbar-track {
  background: #fff !important;
  border: 0 #fff !important;
  border-radius: 15px !important;
}
// SCROLL BAR

@import "ngx-toastr/toastr";
// @import "@ng-select/ng-select/themes/default.theme.css";
@import "@ng-select/ng-select/themes/material.theme.css";
// @import "assets/css/ng-select";
@import "assets/css/custom-buttons";
@import "assets/css/custom-bootstrap";
@import "bootstrap/scss/bootstrap";
