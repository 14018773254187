// .btn,
// a {
//   line-height: normal !important;
// }

button.btn-3d:not(:disabled) {
  box-shadow: 2px 2px 0px 0px #000 !important;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;

  &:hover {
    box-shadow: none !important;
  }
}
