.clickable {
  cursor: pointer;
}

.buttons-action {
  display: grid;
}

.table td {
  vertical-align: middle;
}

.table tr {
  height: 48px;
}