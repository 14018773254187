@font-face {
  font-family: "AcuminProBold", sans-serif;
  src: url("/assets/fonts/AcuminProBold.otf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "AcuminProBook", sans-serif;
  src: url("/assets/fonts/AcuminProBook.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AcuminProMedium", sans-serif;
  src: url("/assets/fonts/AcuminProMedium.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AcuminProSemibold", sans-serif;
  src: url("/assets/fonts/AcuminProSemibold.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AcuminProLight", sans-serif;
  src: url("/assets/fonts/AcuminProLight.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AcuminProExtraLight", sans-serif;
  src: url("/assets/fonts/AcuminProExtraLight.otf");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "AcuminProThin", sans-serif;
  src: url("/assets/fonts/AcuminProThin.otf");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "AcuminProBookItalic", sans-serif;
  src: url("/assets/fonts/AcuminProBookItalic.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AcuminProSemiboldItalic", sans-serif;
  src: url("/assets/fonts/AcuminProSemiboldItalic.otf");
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-h {
  font-family: "AcuminProBold", sans-serif !important;
  line-height: normal !important;
}

.font-h1 {
  font-family: "AcuminProBold", sans-serif !important;
  font-size: 22px;
}

.font-semibold,
strong,
button,
a.btn {
  font-family: "AcuminProSemibold", sans-serif !important;
}

.font-book,
a:not(.btn) {
  font-family: "AcuminProBook", sans-serif !important;
}

input,
.font-regular,
.font-normal,
.fw-normal,
.fw-regular {
  font-family: "AcuminProMedium", sans-serif !important;
}

small,
.small,
.font-light,
.fw-light {
  font-family: "AcuminProExtraLight", sans-serif !important;
}

.xsmall {
  font-family: "AcuminProThin", sans-serif !important;
  font-size: 0.8em !important;
}

.italic-book,
.font-italic {
  font-family: "AcuminProBookItalic", sans-serif !important;
}

.question-widget .btn {
  font-family: "AcuminProSemibold", sans-serif !important;
  text-transform: uppercase;
  border-width: 2px !important;
}
.question-widget:not(input) {
  font-family: "AcuminProBookItalic", sans-serif !important;
}
